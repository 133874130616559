import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {changeTempModule, setLoader} from "../../store/siteData";
import {Button} from "../../components/ui/button/button";
import {ModuleText} from "./module-text";

import {BiText} from 'react-icons/bi';
import {BiImage} from 'react-icons/bi';

export const EditorStructure = ({data}) => {

    const dispatch = useDispatch();
    // получение переключателя из redux
    // при изменении которого (в любом месте страниц редактора) повторно получается дата из БД
    // а на текущей странице обнуляется временный модуль, т.е. выполнение handleCancel
    let trigger = useSelector(state => state.module.trigger);

    let [editorState, setEditorState] = useState({
        data: data.data,
        loading: false,
        error: false,
        addModeEnabled: false,
        currentModule: '',
    });

    // включение отображение режима кнопок
    const handleAdd = () => {
        setEditorState(prevState => {
            return {
                ...prevState,
                addModeEnabled: true,
            }
        });
    }

    // отключение режима отображения кнопок и обнуление временного модуля в превью
    const handleCancel = () => {
        dispatch(changeTempModule([]));
        setEditorState(prevState => {
            return {
                ...prevState,
                addModeEnabled: false,
                currentModule: '',
            }
        });
    }

    // включение модуля для добавления к странице
    const enableModule = (module) => {
        setEditorState(prevState => {
            return {
                ...prevState,
                currentModule: module
            }
        })
    }

    // при переключении триггера сохранения - обнуление временных данных и закрытие текущего модуля
    useEffect(() => {
        handleCancel();
    }, [trigger])

    return (
        <div>
            <div className='em-new-part'>
                {editorState.addModeEnabled ?
                    <>
                        <Button
                            handleClick={() => enableModule('text')}
                            extraClassName={`btn-blue ${editorState.currentModule === 'text' ? '' : 'btn-el-add'}`}
                            text='Текст'
                            icon={<BiText/>}
                        />
                        <Button
                            handleClick={() => enableModule('image')}
                            extraClassName={`btn-blue ${editorState.currentModule === 'image' ? '' : 'btn-el-add'}`}
                            text='Картинка'
                            icon={<BiImage/>}
                        />
                        <Button
                            handleClick={handleCancel}
                            extraClassName='btn-red'
                            text='Отмена'
                        />
                    </> :
                    <Button
                        handleClick={handleAdd}
                        extraClassName='btn-violet'
                        text='Добавить блок'
                    />
                }
            </div>
            {(
                () => {
                    switch (editorState.currentModule) {
                        case 'text':
                            return (<ModuleText/>)
                        case 'image':
                            return (<p>Image</p>)
                        case '':
                        default:
                            return ('')
                    }
                }
            )()}
        </div>
    )

}