import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from "../../components/ui/button/button";

export const ProfileActivation = ({data}) => {

    const navigate = useNavigate();

    // переход на страницу оплаты
    const GoToPayment = () => {
        navigate('/payment', {replace: true}); // редирект на главную
    }

    // пополнить могут только те, у кого осталось меньше 3 месяцев до истечения
    let today = new Date(); // сегодня
    let plus3Month = new Date();
    plus3Month.setMonth(today.getMonth() + 3); // сегодня + 3 месяца

    return (
        <div className="content-wrap activation-wrap">
            {
                // дата истечения раньше, чем сегодня?
                data.active_till < today.toISOString().split('T')[0] ?
                    <>
                        <p>Ваш акаунт неактивний</p>
                        <Button
                            text='Активувати'
                            handleClick={() => GoToPayment()}
                            extraClassName='btn-blue'
                        />
                    </> :
                    <>
                        <p>Ваш акаунт <span className='green'><b>активний</b></span> до <b>{data.active_till}</b> включно</p>
                        {
                            // если дата истечения услуги меньше, чем сегодня + 3 месяца
                            data.active_till < plus3Month.toISOString().split('T')[0] ?
                                <Button
                                    text='Поповнити'
                                    handleClick={() => GoToPayment()}
                                    extraClassName='btn-blue'
                                /> : null
                        }
                    </>
            }
        </div>
    )

}