import React from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {openModal} from "../../store/modalSlice";
import {Button} from "../ui/button/button";

import './footer.scss';

import flag from "../../images/icons/ua-flag.svg";

export const Footer = () => {

    const dispatch = useDispatch();

    // переключение на модальное окно с регистрацией
    const showForm = (modalName) => {
        dispatch(openModal(modalName));
    }

    return(
        <footer>
            <div className="f-left">
                <p>Задати будь-яке питання</p>
                <Button
                    handleClick={() => showForm('form')}
                    extraClassName='btn-violet'
                    text='Написати'
                />
                <p className='copyright'><span>Telnet &copy;</span> <span>Зроблено в <img src={flag} alt="ua flag"/></span></p>
            </div>
            <div className="f-right">
                <ul>
                    <li><NavLink to='faq'>Часті питання</NavLink></li>
                    <li><NavLink to='logs'>Журнал розробки</NavLink></li>
                </ul>
            </div>
        </footer>
    )

}