import React from 'react';
import {GetData} from "../../components/get-data/get-data";
import {Loading} from "../../components/loading/loading";
import {ProfileLink} from "./profile-link";
import {ProfileEmail} from "./profile-email";
import {ProfileActivation} from "./profile-activation";
import {ProfileStatus} from "./profile-status";
import {ProfilePassword} from "./profile-password";

import './profile.scss';

export const Profile = () => {

    // данные пользователя
    let data = GetData(false);

    return (
        <div className='profile-page'>
            <h1 className='no-margin'>Ваш профіль</h1>
            {data ?
                <>
                    <p className='login-under-title'>({
                        (data.googleName.length > 0) ? data.googleName : data.login
                    })</p>
                    <div className='profile-card'><ProfileActivation data={data}/></div>
                    <div className='profile-card'><ProfileLink data={data}/></div>
                    <div className='profile-card'><ProfileEmail data={data}/></div>
                    <div className='profile-card'><ProfilePassword data={data}/></div>
                    <div className='profile-card'><ProfileStatus data={data}/></div>
                </> :
                <Loading isLoading={true} size='large'/>
            }
        </div>
    )

}