import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {LiqPay} from "../../components/liqpay/liqpay";
import {GetData} from "../../components/get-data/get-data";
import {Loading} from "../../components/loading/loading";

import './payment.scss';

export const Payment = () => {

    let intervalID; // будущая функция для получения статуса оплаты

    let [liqpayState, setLiqpayState] = useState({
        loading: true,
        paymentSuccess: false,
        error: false,
        paymentTrigger: false, // при изменении этого параметра - данные про пользователя (дата активации) будут получены повторно
    });

    let data = GetData(liqpayState.paymentTrigger);

    // пополнить могут только те, у кого осталось меньше 3 месяцев и 1 дня до истечения
    let today = new Date(); // сегодня
    let plus3Month = new Date();
    plus3Month.setMonth(today.getMonth() + 3); // сегодня + 3 месяца
    plus3Month.setDate(plus3Month.getDate() + 1); // и ещё плюс один день, чтобы пополннить можно было сразу второй раз

    // получение данных для кнопки оплаты
    const checkPayProcess = () => {
        setLiqpayState(prevState => {
            return {
                ...prevState,
                error: false,
            }
        })
        fetch('https://tel.net.ua/telnet/backend/payment/pay-process.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setLiqpayState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            error: true,
                        }
                    })
                } else {
                    // если оплата ещё в процессе - прелоадер продолжает работать
                    let process = response.result === 'ok'; // если оплаты на текущий момент нет
                    let update = response.result === 'update'; // если оплата была и она успешно обработана
                    if (process || update) {
                        window.clearInterval(intervalID);
                        setLiqpayState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                                paymentSuccess: true,
                            }
                        })
                    }
                    // если данные были обновлены - дополнительно получение новой даты
                    if (update) {
                        setLiqpayState(prevState => {
                            return {
                                ...prevState,
                                paymentTrigger: true,
                            }
                        })
                    }
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                setLiqpayState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                })
            })
    }

    // при прогрузке страницы и получения данных о пользователе
    useEffect(() => {
        if (data !== false) {
            checkPayProcess();
            let payRepeats = 0; // счётчик попыток получить информацию о оплате
            intervalID = setInterval(function () {
                // при последней итерации, если оплата не перестала быть in process - ошибка
                if (++payRepeats === 5) {
                    window.clearInterval(intervalID);
                    setLiqpayState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            error: true,
                        }
                    })
                } else checkPayProcess();
            }, 1000);
        }
    }, [data, liqpayState.paymentSuccess])

    return (
        <div className='payment-page'>
            <h1>Підписка</h1>
            {/* если есть данные о пользователе и нет ожидания оплаты */}
            {(data && !liqpayState.loading) ?
                <>
                    {liqpayState.error ?
                        <p className='red content-wrap'>
                            Помилка при отриманні даних про оплату. Оновіть сторінку, якщо ця помилка не зникла - зверніться до <NavLink to='support'><u>техпідтримки</u></NavLink>
                        </p> :
                        <>
                            {   // дата истечения раньше, чем сегодня?
                                data.active_till < today.toISOString().split('T')[0] ?
                                    <>
                                        <p>Наразі ваша підписка <span className="red">не активована</span></p>
                                        <div className='content-wrap pay-wrap'>
                                            <p>Сплатити за 3 місяці</p>
                                            <p>Вартість <span>30 грн/міс.</span></p>
                                            <LiqPay price={2} months={3}/>
                                        </div>
                                        <div className='content-wrap pay-wrap'>
                                            <p>Сплатити за рік</p>
                                            <p>Вартість <span>25 грн/міс.</span></p>
                                            <LiqPay price={1} months={12}/>
                                        </div>
                                    </> :
                                    <>
                                        <p>Ваша підписка <span className='green'><b>активована</b></span> до <b>{data.active_till}</b> включно</p>
                                        {
                                            // если дата истечения услуги меньше, чем сегодня + 3 месяца
                                            data.active_till < plus3Month.toISOString().split('T')[0] ?
                                                <>
                                                    <div className='content-wrap pay-wrap'>
                                                        <p>Подовжити підписку на 3 місяці</p>
                                                        <p>Вартість <span>30 грн/міс.</span></p>
                                                        <LiqPay price={2} months={3}/>
                                                    </div>
                                                    <div className='content-wrap pay-wrap'>
                                                        <p>Подовжити підписку на рік</p>
                                                        <p>Вартість <span>25 грн/міс.</span></p>
                                                        <LiqPay price={1} months={12}/>
                                                    </div>
                                                </> : null
                                        }
                                    </>
                            }
                        </>
                    }
                </> : <Loading isLoading={true} size='large'/>
            }
        </div>
    )
}