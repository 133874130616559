import React from 'react';
import {Index} from "./pages/index";
import { gapi } from "gapi-script";

import './app.scss';

// для работы ключа Гугл авторизации или регистрации
gapi.load("client:auth2", () => {
    gapi.auth2.init({
        clientId:
            "893354226606-c123ful2o78hjonbje0qnm08c4o2hl11.apps.googleusercontent.com",
        plugin_name: "chat",
    });
});

function App() {
    return (
        <Index/>
    );
}

export default App;