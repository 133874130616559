import React from 'react';

import {ErrorLine} from "../text/error-line/error-line";

import preloaderSmall from "../../images/icons/preloader-small.svg";
import preloaderMedium from "../../images/icons/preloader-medium.svg";
import preloaderLarge from "../../images/icons/preloader-large.svg";

import './loading.scss';

export const Loading = ({children, isLoading = false, alreadyLoaded = false, loadError = false, width = false, height = false, size = 'small', extraClassName = ''}) => {

    let preloader; // установка размера иконки прелоадера
    if(size === 'large') {
        preloader = preloaderLarge;
    } else if(size === 'medium') {
        preloader = preloaderMedium;
    } else preloader = preloaderSmall;

    const preloaderArea = {width: width, height: height};

    return (
        // если контент ещё ни разу не загрузился - размер по умолчанию размера шаблона
        // если дополнительно указаны фиксированные размеры - добавление их с помощью style
        <div className={`loading-wrap ${extraClassName} ${alreadyLoaded ? 'loading-wrap-already' : ''} ${(!alreadyLoaded && isLoading) ? `loading-wrap-${size}` : ''} ${loadError ? 'loading-error' : ''}`}
             style={alreadyLoaded ? {width: '100%', height: '100%'} : preloaderArea}>
            {/* происходит ли сейчас загрузка данных - отображение спиннера с размерами существующего контента (или контента по умолчанию) */}
            {isLoading ?
                <div className='loading'><img src={preloader} alt="preloader"/></div> : null
            }
            {loadError ? <ErrorLine text='Данные не получены'/> : children}
        </div>
    )
}