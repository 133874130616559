import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Loading} from "../../components/loading/loading";
import {Input} from "../../components/ui/input/input";
import {Button} from "../../components/ui/button/button";
import {ErrorLine} from "../../components/text/error-line/error-line";

export const ProfileEmail = ({data}) => {

    let [emailState, setEmailState] = useState({
        email: data.email,
        emailSet: data.email.length > 0, // true/false
        newEmail: '',
        loading: false,
        error: false,
        exists: false,
        emailInvalid: false,
    });

    // изменение инпута почты
    const onEmailChange = (e) => {
        setEmailState(prevState => { // отображение ошибки
            return {
                ...prevState,
                newEmail: e.target.value,
                error: false,
                exists: false,
                emailInvalid: false,
            }
        });
    }

    // нажатие на Ентер при фокусе на текстовом поле
    const onEmailKeydown = (e) => {
        if (e.key === 'Enter' && emailState.newEmail.length > 0) saveEmail();
    }

    // сохранение нового Email
    const saveEmail = () => {

        let email = emailState.newEmail;

        // включение прелоадера
        setEmailState(prevState => {
            return {
                ...prevState,
                loading: true,
                error: false,
                exists: false,
                emailInvalid: false,
            }
        });

        // валидация почты
        let reg = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (!reg.test(email)) {
            setEmailState(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    emailInvalid: true,
                }
            });
            return;
        }

        // сохранение нового Email
        fetch('https://tel.net.ua/telnet/backend/profile/save-email.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                email: email,
            })
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    // выключение прелоадера и отображение ошибки
                    if (response.error === 'exists') {
                        setEmailState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                                exists: true,
                            }
                        });
                    } else if (response.error === 'email') {
                        setEmailState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                                emailInvalid: true,
                            }
                        });
                    } else {
                        setEmailState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                                error: true,
                            }
                        });
                    }
                } else {
                    // отображение ссылки и выключение ошибок
                    setEmailState(prevState => {
                        return {
                            ...prevState,
                            email: email,
                            newEmail: '',
                            emailSet: true,
                            loading: false,
                            error: false,
                        }
                    });
                }
            })
            .catch(() => {
                // выключение прелоадера и отображение ошибки
                setEmailState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                });
            })
    }

    return (
        <Loading alreadyLoaded={true} isLoading={emailState.loading}>
            <div className="content-wrap email-wrap">
                {emailState.emailSet ?
                    <div className='your-email-wrap'>
                        <p className='your-email'>Ваша пошта: <span>{emailState.email}</span></p>
                        {data.googleName.length > 0 ?
                            <p>Для користувачів, зареєстрованих через Google, пошту міняти не можна. Але якщо вам потрібно змінити пошту в будь-якому випадку або перенести акаунт - зверніться до <NavLink to='support'><u>техпідтримки</u></NavLink></p> :
                            <>
                                <p>Змінити пошту:</p>
                                <div className='set-link-form'>
                                    <div className='set-link-form-inner'>
                                        <Input
                                            value={emailState.newEmail}
                                            handleChange={(e) => onEmailChange(e)}
                                            handleKeyDown={(e) => onEmailKeydown(e)}
                                        />
                                        {emailState.newEmail.length > 0 ?
                                            <Button
                                                extraClassName='btn-blue'
                                                handleClick={saveEmail}
                                                text='Змінити'
                                            /> :
                                            <Button
                                                extraClassName='btn-gray'
                                                text='Змінити'
                                                disable='disabled'
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div> :
                    <div>
                        <p>Будь ласка, вкажіть вашу пошту</p>
                        <div className='profile-input-btn'>
                            <Input
                                value={emailState.newEmail}
                                handleChange={(e) => onEmailChange(e)}
                                handleKeyDown={(e) => onEmailKeydown(e)}
                            />
                            {emailState.newEmail.length > 0 ?
                                <Button
                                    extraClassName='btn-blue'
                                    handleClick={saveEmail}
                                    text='Зберегти'
                                /> :
                                <Button
                                    extraClassName='btn-gray'
                                    text='Зберегти'
                                    disable='disabled'
                                />
                            }
                        </div>
                        <p>Пошта буде використовуватися для отримання важливих повідомлень</p>
                    </div>
                }
                {emailState.exists ? <ErrorLine text='Така пошта вже використовується'/> : null}
                {emailState.emailInvalid ? <ErrorLine text='Перевірте правильність написання пошти'/> : null}
                {emailState.error ? <p className='red'>Помилка збереження. Зверніться до <NavLink to='support'><u>техпідтримки</u></NavLink></p> : null}
            </div>
        </Loading>
    )

}