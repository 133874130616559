import React from 'react';

import './button.scss';

export const Button = ({handleClick, text = '', extraClassName = '', icon = '', disable = false}) => {

    const disabled = disable ? 'disabled' : '';

    return (
        <button
            onClick={handleClick}
            className={`btn ${extraClassName}`}
            disabled={disabled}
        >{text}{icon}</button>
    )
}