import React from 'react';

import './preview-parts.scss';

export const PreviewParts = ({partData}) => {

    // TODO: объединить с editor-parts в переиспользуемый компонент

    return (
        <div className="parts-wrap">
            {(() => {
                switch (partData.name) {
                    case 'text':
                        return (
                            <div className="part-wrap">
                                <pre className={`text-${partData.align} 
                                            size-${partData.size}
                                            ${partData.weight !== '0' ? 'weight' : ''}
                                            ${partData.italic !== '0' ? 'italic' : ''}
                                            ${partData.upper !== '0' ? 'upper' : ''}`}>
                                    {partData.text}
                                </pre>
                            </div>
                        )
                    default:
                        return null
                }
            })()}
        </div>
    )
}