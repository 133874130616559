import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logOut} from "../../store/authSlice";

export const GetData = () => {

    console.log('get data')
    let trigger = useSelector(state => state.module.trigger);

    const dispatch = useDispatch();

    // статусы профиля
    let [profileState, setProfileState] = useState({
        data: false,
    });

    // получение данных при первой прогрузке
    const getData = () => {

        console.log('trigger = ' + trigger)

        let loginData = localStorage.getItem('loginData'); // логин/пароль

        // получение данных пользователя
        fetch('https://tel.net.ua/telnet/backend/get-user-data.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: loginData
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    dispatch(logOut()); // данные не получены - логаут
                    // хоть была получена ошибка - данные считаются полученными, но пользователь считается не залогиненным
                    // произойдёт ререндер без данных - т.е. лендинг
                } else {
                    // // сохранение всех данных пользователя в локальное хранилище
                    // localStorage.setItem('userData', JSON.stringify(response));
                    // изменение статуса, что данные получены и можно их использовать из локального хранилища
                    setProfileState(prevState => {
                        return {...prevState, data: response}
                    })
                }
            })
            .catch(() => {
                dispatch(logOut()); // ошибка при получении данных - логаут
                // хоть была получена ошибка - данные считаются полученными, но пользователь считается не залогиненным
                // произойдёт ререндер без данных - т.е. лендинг
            })
    }

    // при прогрузке страницы
    useEffect(() => {
        console.log('use effect get data')
        getData();
    }, [trigger] )

    return profileState.data;

}