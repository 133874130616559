import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {changeTempModule, setLoader, changeTrigger} from "../../store/siteData";
import {GetData} from "../../components/get-data/get-data";
import {Loading} from "../../components/loading/loading";
import {EditorStructure} from "./editor-structure";
import {EditorPreview} from "./editor-preview";

import './editor.scss';
import {EditorExistsData} from "./editor-exists-data";

export const Editor = () => {

    // получение переключателя из redux
    // при изменении которого (в любом месте страниц редактора) повторно получается дата из БД
    // let trigger = useSelector(state => state.module.trigger);
    let data = GetData();

    let allPageData = []; // по умолчанию и для пререндера данных нет
    // если данные получены
    if(typeof data.data !== 'undefined') {
        // и длина строки json >2 - значит данные есть
        if(data.data.length > 2) allPageData = JSON.parse(data.data);
    }

    console.log('editor')
    console.log(data)
    console.log(allPageData)

    // let [editorState, setEditorState] = useState({
    //     trigger: true,
    // });
    //
    // // при прогрузке модуля удаляются предыдущие временные настройки
    // const dispatch = useDispatch();
    // dispatch(setLoader(false));
    //
    // const allPageData = useSelector(state => state.module.allPageData);
    // console.log(allPageData)
    //
    // // данные пользователя
    // let data = GetData(editorState.trigger);
    // console.log(data)
    //
    // // при сохранении повторно получаются данные для ререндера
    // // текущая функция передаётся напрямую к блоку создания новой части контента
    // // а на странице уже существующих отдельно сделан аналог этой функции, чтобы не использовать "бурение данных" через все компоненты
    // const onSave = () => {
    //     setEditorState(prevState => {
    //         return {
    //             ...prevState,
    //             trigger: !editorState.trigger,
    //         }
    //     });
    // }
    //
    // // временный модуль определяется после прогрузки во избежания:
    // // Cannot update a component (`EditorPreview`) while rendering a different component (`Editor`)
    // useEffect(() => {
    //     dispatch(changeTempModule([]));
    // }, [] )

    return (
        <div>
            <h1>Ваша сторінка</h1>
            {data ?
                <div className='editor-wrap'>
                    <div className="content-wrap editor-main">
                        <p>Описание</p>
                        <EditorExistsData data={allPageData} />
                        <EditorStructure data={allPageData}/>
                    </div>
                    <EditorPreview data={allPageData}/>
                </div> :
                <Loading isLoading={true} size='large'/>
            }
        </div>
    )

}