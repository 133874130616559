import React, {useState} from 'react';
import {Input} from "../../components/ui/input/input";
import {Button} from "../../components/ui/button/button";
import {Loading} from "../../components/loading/loading";
import {ErrorLine} from "../../components/text/error-line/error-line";

export const ProfileLink = ({data}) => {

    let [linkState, setLinkState] = useState({
        link: data.link,
        newLink: '',
        linkSet: data.link.length > 0, // true/false
        loading: false,
        error: false,
        exists: false,
        linkInvalid: false,
        copyText: 'Скопіювати',
        copyClass: 'btn-blue',
    });

    // изменение инпута ссылки
    const onLinkChange = (e) => {

        const reg = /[^a-z_\d]/i;
        let link = e.target.value;
        let unusedLetters = link.search(/[^a-z_\d]/i); // поиск запрещённых символов
        link = link.replace(reg, ''); // удаление всех запрещённых символов
        // если найден запрещённый символ
        if(unusedLetters !== -1) {
            setLinkState(prevState => { // отображение ошибки
                return {...prevState, newLink: link, exists: false, linkInvalid: true}
            });
        } else {
            setLinkState(prevState => {
                return {...prevState, newLink: link, exists: false, linkInvalid: false}
            });
        }
    }

    // нажатие на Ентер при фокусе на текстовом поле
    const onLinkKeydown = (e) => {
        if (e.key === 'Enter' && linkState.newLink.length > 0) saveLink();
    }

    // сохранение новой ссылки
    const saveLink = () => {
        // включение прелоадера
        setLinkState(prevState => {
            return {
                ...prevState,
                loading: true,
                error: false,
                exists: false,
            }
        });
        // сохранение новой ссылки
        fetch('https://tel.net.ua/telnet/backend/profile/save-link.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                link: linkState.newLink,
            })
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    // выключение прелоадера и отображение ошибки
                    if(response.error === 'exists') {
                        setLinkState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                                exists: true,
                            }
                        });
                    } else {
                        setLinkState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                                error: true,
                            }
                        });
                    }
                } else {
                    // отображение ссылки и выключение ошибок
                    setLinkState(prevState => {
                        return {
                            ...prevState,
                            link: linkState.newLink,
                            newLink: '',
                            linkSet: true,
                            loading: false,
                            error: false,
                            copyText: 'Скопіювати',
                            copyClass: 'btn-blue',
                        }
                    });
                }
            })
            .catch(() => {
                // выключение прелоадера и отображение ошибки
                setLinkState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                });
            })
    }

    // удаление ссылки
    const removeLink = () => {
        // включение прелоадера
        setLinkState(prevState => {
            return {
                ...prevState,
                loading: true,
                error: false,
                exists: false,
            }
        });
        // удаление ссылки
        fetch('https://tel.net.ua/telnet/backend/profile/delete-link.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
            })
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    // выключение прелоадера и отображение ошибки
                    setLinkState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            error: true,
                        }
                    });
                } else {
                    // отображение ссылки и выключение ошибок
                    setLinkState(prevState => {
                        return {
                            ...prevState,
                            link: '',
                            newLink: '',
                            linkSet: false,
                            loading: false,
                            error: false,
                        }
                    });
                }
            })
            .catch(() => {
                // выключение прелоадера и отображение ошибки
                setLinkState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                });
            })
    }

    // при ошибке сохранения - клик по Попробовать ещё раз
    const TryAgain = () => {
        setLinkState(prevState => {
            return {
                ...prevState,
                newLink: '',
                loading: false,
                error: false,
            }
        });
    }

    // скопировать ссылку в буфер
    const CopyLink = () => {
        setLinkState(prevState => {
            return {
                ...prevState,
                copyText: 'Скопійовано',
                copyClass: 'btn-violet',
            }
        });
        navigator.clipboard.writeText('https://tel.net.ua/' + linkState.link)
    }

    return (
        <Loading alreadyLoaded={true} isLoading={linkState.loading}>
            {linkState.error > 0 ?
                <div className='content-wrap profile-link-error'>
                    <ErrorLine text='Помилка. Зверніться до підтримки'/>
                    <Button handleClick={TryAgain} extraClassName='btn-violet' text='Спробувати ще раз'/>
                </div> :
                <div className='content-wrap profile-link'>
                    {linkState.linkSet ?
                        <>
                            <p>Ваше мультипосилання:</p>
                            <p className='link-wrap'>
                                <a href={`https://tel.net.ua/${linkState.link}`} className='link-url' target="_blank">
                                    https://tel.net.ua/<span>{linkState.link}</span>
                                </a>
                                <Button
                                    handleClick={CopyLink}
                                    extraClassName={linkState.copyClass}
                                    text={linkState.copyText}
                                />
                            </p>
                            <p>Ви завжди можете змінити ваше посилання</p>
                            <div className='profile-input-btn'>
                                <Input
                                    value={linkState.newLink}
                                    handleChange={(e) => onLinkChange(e)}
                                    handleKeyDown={(e) => onLinkKeydown(e)}
                                />
                                {linkState.newLink.length > 0 && linkState.newLink !== linkState.link ?
                                    <Button
                                        extraClassName='btn-blue'
                                        handleClick={saveLink}
                                        text='Змінити'
                                    /> :
                                    <Button
                                        extraClassName='btn-gray'
                                        text='Змінити'
                                        disable='disabled'
                                    />
                                }
                            </div>
                            {linkState.exists ? <ErrorLine text='Таке посилання вже зайняте'/> : null }
                            {linkState.linkInvalid ? <ErrorLine text='Можна використовувати латинські літери, цифри та символи "/" "_"'/> : null }
                            <p className='mb5'>Також ви можете видалити посилання, але воно в такому випадку буде доступне для використання іншим користувачам</p>
                            <div>
                                <Button
                                    handleClick={removeLink}
                                    extraClassName='btn-red'
                                    text='Видалити'
                                />
                            </div>
                        </> :
                        <>
                            <p>Ваше мультипосилання не встановлено. Для початку роботи необхідно вибрати ім'я посилання. Ви
                                завжди зможете його поміняти</p>
                            <div className='set-link-form'>
                                <p>https://tel.net.ua/</p>
                                <div className='set-link-form-inner'>
                                    <Input
                                        value={linkState.newLink}
                                        handleChange={(e) => onLinkChange(e)}
                                        handleKeyDown={(e) => onLinkKeydown(e)}
                                    />
                                    {linkState.newLink.length > 0 ?
                                        <Button
                                            extraClassName='btn-blue'
                                            handleClick={saveLink}
                                            text='Зберегти'
                                        /> :
                                        <Button
                                            extraClassName='btn-gray'
                                            text='Зберегти'
                                            disable='disabled'
                                        />
                                    }
                                </div>
                            </div>
                            {linkState.exists ? <ErrorLine text='Таке посилання вже зайняте'/> : null }
                            {linkState.linkInvalid ? <ErrorLine text='Можна використовувати латинські літери, цифри та символи "/" "_"'/> : null }
                        </>
                    }
                </div>
            }
        </Loading>
    )

}