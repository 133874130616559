import React, {useState} from 'react';
import {Routes, Route, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Dashboard} from "./dashboard";
import {Header} from "../../components/header/header";
import {Button} from "../../components/ui/button/button";
import {Modal} from "../../components/modal/modal";
import {logIn, logOut} from "../../store/authSlice";
import {GoogleButtonAuth} from "../../components/ui/google-button-auth/google-button-auth";
import {Loading} from "../../components/loading/loading";
import {Footer} from "../../components/footer/footer";
import {Faq} from "../faq/faq";
import {Logs} from "../logs/logs";

import './index.scss';

import {BiLink} from 'react-icons/bi';
import {RiDashboardLine} from 'react-icons/ri';

export const Index = () => {

    const dispatch = useDispatch();
    let isLogin = useSelector(state => state.auth.isLogin); // глобальная переменная, залогинен ли пользователь
    let loginData = localStorage.getItem('loginData'); // данные о авторизации пользователя (логин/пароль)

    // пока данные не получены - пользователь не авторизирован
    let [loginStatusState, setLoginStatusState] = useState({
        dataReceived: false, // для проверки, что данные получены от БД, а не локально
    })

    // если в локальном хранилище есть данные авторизации - предварительно считается, что пользователь залогинен
    if (loginData) isLogin = true;

    // если пользователь считается залогиненным - получение данных (попытка)
    if (loginData && !loginStatusState.dataReceived) {
        // проверка валидности данных авторизации и получение всех данных пользователя
        fetch('https://tel.net.ua/telnet/backend/get-user-data.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: loginData
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    dispatch(logOut()); // данные не получены - логаут
                    // хоть была получена ошибка - данные считаются полученными, но пользователь считается не залогиненным
                    // произойдёт ререндер без данных - т.е. лендинг
                    setLoginStatusState(prevState => {
                        return {...prevState, dataReceived: true}
                    })
                } else {
                    // сохранение всех данных пользователя в локальное хранилище
                    localStorage.setItem('userData', JSON.stringify(response));
                    dispatch(logIn()); // redux что пользователь залогинен
                    // изменение статуса, что данные получены и можно их использовать из локального хранилища
                    setLoginStatusState(prevState => {
                        return {...prevState, dataReceived: true}
                    })
                }
            })
            .catch(() => {
                dispatch(logOut()); // ошибка при получении данных - логаут
                // хоть была получена ошибка - данные считаются полученными, но пользователь считается не залогиненным
                // произойдёт ререндер без данных - т.е. лендинг
                setLoginStatusState(prevState => {
                    return {...prevState, dataReceived: true}
                })
            })
    }

    // ошибка при авторизации через гугл на главной странице
    const handleFailure = (params) => {
        if(params.error === 'popup_closed_by_user') return;
        alert('Сталася помилка. Зверніться у підтримку');
    }

    return (
        <div className="App">
            <Routes basename='/'>
                <Route path='faq' element={<Faq/>}/>
                <Route path='logs' element={<Logs/>}/>
                <Route path='*' element={
                    (() => {
                        // если пользователь считается залогиненным
                        if (isLogin) {
                            // и если уже получены все данные
                            if (loginStatusState.dataReceived) {
                                return (<Dashboard/>)
                            } else { // иначе ожидание получения данных
                                return (
                                    <div className='loading-dashboard'>
                                        <Loading isLoading={true} size='large' alreadyLoaded={true} extraClassName='fullwidth'/>
                                    </div>
                                )
                            }
                        } else {
                            // иначе пользователь не считается залогиненным - вывод лендинга
                            return (
                                <>
                                    <div className="intro">
                                        <Header/>
                                        <div className='inner'>
                                            <h1>Візитка для Вашого бізнесу</h1>
                                            <p className='subtitle'>Місце, де розміщена вся інформація про вас: соцмережі, портфоліо, контакти та багато іншого</p>
                                            <div className="intro-btns">
                                                <NavLink to={'/'}>
                                                    <Button extraClassName='btn-violet' text='Спробувати без реєстрації'/>
                                                </NavLink>
                                                <GoogleButtonAuth
                                                    text='Увійти через Google'
                                                    extraClassName='btn-blue'
                                                    handleFailure={handleFailure}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps">
                                        <div className="inner">
                                            <p className='steps-title'>Всього <span>2</span> прості кроки для створення лаконічної сторінки</p>
                                            <div className='steps-single'>
                                                <p><span className='steps-number'>1</span></p>
                                                <p>Швидка реєстрація в 1 клік через <span>Google</span> або логін/пароль</p>
                                            </div>
                                            <div className='steps-single'>
                                                <p><span className='steps-number'>2</span></p>
                                                <p>Створення контент-блоків за допомогою зручного конструктора</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="you-receive">
                                        <div className="inner">
                                            <p className="you-receive-title">Ви отримуєте</p>
                                            <div className="you-receive-single">
                                                <p><span><BiLink/></span></p>
                                                <p>Мультипосилання, яке можна розмістити в інстаграмі або будь-якій іншій соцмережі</p>
                                            </div>
                                            <div className="you-receive-single">
                                                <p><span><RiDashboardLine/></span></p>
                                                <p>Панель керування всіма Вашими інформаційними блоками та контактними даними</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price">
                                        <div className="inner">
                                            <p className="price-title">Ціна</p>
                                            <p className="price-line"><span>30 грн/міс.</span> при оплаті за 3 місяці</p>
                                            <p className="price-line"><span>25 грн/міс.</span> при оплаті за рік</p>
                                            <p className='price-note'><span>*</span>при реєстрації ви отримуєте відразу активний акаунт із пробним періодом на 1 день</p>
                                            <p className='price-try'>Ви можете спробувати створити сторінку без реєстрації прямо зараз</p>
                                            <p className='text-center'>
                                                <Button extraClassName='btn-blue' text='Спробувати без реєстрації'/>
                                            </p>
                                        </div>
                                    </div>
                                    <Footer/>
                                    <Modal/>
                                </>
                            )
                        }
                    })()
                }/>
            </Routes>
        </div>
    )

}