import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {openModal} from "../../store/modalSlice";

import './modal.scss';
import {Loading} from "../loading/loading";

export const ModalTemplate = ({title, body, size}) => {

    const dispatch = useDispatch();

    // прелоадер поверх модального окна
    const modalName = useSelector(state => state.modals.loading);

    const burgerClick = (modalName) => {
        dispatch(openModal(modalName));
    }

    size = size ? size : 'default';

    return (
        <div className={`modal-wrap modal-${size}`}>
            <Loading isLoading={modalName} size="medium">
                <div className="modal">
                    <div className="modal-header">
                        <p>{title}</p>
                        <span className="modal-close" onClick={() => burgerClick('false')}/>
                    </div>
                    <div className="modal-body">
                        {body}
                    </div>
                </div>
                <div className="overlay" onClick={() => burgerClick('false')}/>
            </Loading>
        </div>
    )
}