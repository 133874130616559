import React from 'react';
import './input.scss';

export const Input = ({handleChange, handleKeyDown, type, placeholder, extraClassName = '', ...props}) => {
    return (
        <input
            className={`input ${extraClassName}`}
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            {...props}
        />
    )
}