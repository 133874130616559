import React from 'react';

export const Faq = () => {

    return (
        <div>
            <h1>FAQ</h1>
        </div>
    )

}