import {createSlice} from '@reduxjs/toolkit';

export const PagesSlice = createSlice({
    name: 'pages',
    initialState: {
        current: 'profile',
    },
    reducers: {
      changePage(state, action) {
        state.current = action.payload;
      },
    },
});

export const {changePage} = PagesSlice.actions;

export default PagesSlice.reducer;