import {configureStore} from '@reduxjs/toolkit';
import modalReducer from './modalSlice';
import authReducer from './authSlice';
import pagesReducer from './pagesSlice';
import moduleReducer from './siteData';

export default configureStore({
   reducer: {
       modals: modalReducer, // модальные окна (либо имя открытого окна, либо false)
       auth: authReducer, // переключатель авторизации true/false
       pages: pagesReducer, // переключение страниц внутри dashboard
       module: moduleReducer, // изменение добавляемого модуля на страницу
   }
});