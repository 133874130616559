import React from 'react';

export const Logs = () => {

    return (
        <div>
            Logs
        </div>
    )

}