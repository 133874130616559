import {createSlice} from '@reduxjs/toolkit';

export const SiteData = createSlice({
    name: 'pages',
    initialState: {
        allPageData: [], // все блоки страницы пользователя
        module: [], // новый блок контента, который создаётся на текущий момент
        loading: false, // вкл/выкл прелоадера во время изменения контента страницы
        trigger: false, // переключатель, при изменении которого получается из БД контент страницы
    },
    reducers: {
        changeTempModule(state, action) {
            state.module = action.payload;
        },
        setLoader(state, action) {
            state.loading = action.payload;
        },
        changeTrigger(state, action) {
            console.log('trigger')
            // console.log(action.payload)
            // state.allPageData = [];
            state.trigger = action.payload;
        },
    },
});

export const {changeTempModule, setLoader, changeTrigger} = SiteData.actions;

export default SiteData.reducer;