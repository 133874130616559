import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {openModal, switchLoading} from "../../store/modalSlice";
import {Input} from "../ui/input/input";
import {Label} from "../ui/label/label";
import {Button} from "../ui/button/button";

import './modal-send-message.scss';
import {ErrorLine} from "../text/error-line/error-line";
import {Loading} from "../loading/loading";
import {SuccessLine} from "../text/success-line/success-line";

export const ModalSendMessage = ({inlineForm = false}) => {

    const dispatch = useDispatch();

    // состояние текущей формы
    let [loginState, setLoginState] = useState({
        email: '',
        message: '',
        loading: false,
        emailEmpty: false,
        messageEmpty: false,
        emailInvalid: false,
        errorSubmit: false,
        success: false,
    });

    // попытка отправки письма
    const submitForm = () => {
        setLoginState(prevState => { // очистка текста ошибок
            return {...prevState,
                emailEmpty: false,
                messageEmpty: false,
                emailInvalid: false,
                errorSubmit: false,
            }
        })

        // включение прелоадера
        dispatch(switchLoading(true));

        // данные почта/сообщение в json
        const userData = JSON.stringify({
            email: loginState.email,
            message: loginState.message,
        });

        // поле E-mail не должно быть пустым
        if(loginState.email.length === 0) {
            setLoginState(prevState => {
                return {...prevState, emailEmpty: true}
            });
            // выключение прелоадера и завершение функции
            dispatch(switchLoading(false));
            return;
        }

        // поле сообщения не должно быть пустым
        if(loginState.message.length === 0) {
            setLoginState(prevState => {
                return {...prevState, messageEmpty: true}
            });
            // выключение прелоадера и завершение функции
            dispatch(switchLoading(false));
            return;
        }

        // проверка валидности поля E-mail
        if (!validateEmail(loginState.email) || validateEmail(loginState.email) === undefined) { // если по регулярному выражению нет совпадений
            setLoginState(prevState => { // очистка текста ошибок
                return {...prevState, emailInvalid: true}
            })
            // выключение прелоадера и завершение функции
            dispatch(switchLoading(false));
            return;
        }

        // отправка письма
        fetch('https://tel.net.ua/telnet/backend/send-message.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: userData
        })
            .then(response => response.json())
            .then(response => {
                // выключение прелоадера
                dispatch(switchLoading(false));
                if (response.error) {
                    // если почта невалидна
                    if(response.error === 'email') {
                        setLoginState(prevState => {
                            return {...prevState, emailInvalid: true}
                        })
                    } else { // любая другая ошибка
                        setLoginState(prevState => {
                            return {...prevState, errorSubmit: true}
                        })
                    }
                } else {
                    dispatch(openModal("send-success"));
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                dispatch(switchLoading(false));
                setLoginState(prevState => {
                    return {...prevState, errorSubmit: true}
                })
            })
    }

    // попытка отправки письма
    const submitInlineForm = () => {

        // включение прелоадера
        setLoginState(prevState => { // очистка текста ошибок
            return {...prevState,
                loading: true,
                emailEmpty: false,
                messageEmpty: false,
                emailInvalid: false,
                errorSubmit: false,
                success: false,
            }
        })

        // данные почта/сообщение в json
        const userData = JSON.stringify({
            email: loginState.email,
            message: loginState.message,
        });

        // поле E-mail не должно быть пустым
        if(loginState.email.length === 0) {
            // выключение прелоадера и завершение функции
            setLoginState(prevState => {
                return {...prevState,
                    emailEmpty: true,
                    loading: false,
                }
            });
            return;
        }

        // поле сообщения не должно быть пустым
        if(loginState.message.length === 0) {
            // выключение прелоадера и завершение функции
            setLoginState(prevState => {
                return {...prevState,
                    messageEmpty: true,
                    loading: false,
                }
            });
            return;
        }

        // проверка валидности поля E-mail
        if (!validateEmail(loginState.email) || validateEmail(loginState.email) === undefined) { // если по регулярному выражению нет совпадений
            // выключение прелоадера и завершение функции
            setLoginState(prevState => { // очистка текста ошибок
                return {...prevState,
                    emailInvalid: true,
                    loading: false,
                }
            })
            return;
        }

        // отправка письма
        fetch('https://tel.net.ua/telnet/backend/send-message.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: userData
        })
            .then(response => response.json())
            .then(response => {
                // выключение прелоадера
                if (response.error) {
                    // если почта невалидна
                    if(response.error === 'email') {
                        setLoginState(prevState => {
                            return {...prevState,
                                emailInvalid: true,
                                loading: false,
                            }
                        })
                    } else { // любая другая ошибка
                        setLoginState(prevState => {
                            return {...prevState,
                                errorSubmit: true,
                                loading: false,
                            }
                        })
                    }
                } else {
                    setLoginState(prevState => {
                        return {...prevState,
                            email: '',
                            message: '',
                            loading: false,
                            success: true,
                        }
                    })
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                setLoginState(prevState => {
                    return {...prevState,
                        errorSubmit: true,
                        loading: false,
                    }
                })
            })
    }

    // валидация на наличие почты в поле (возвращается массив совпадений)
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // нажатие на Ентер при фокусе на текстовом поле
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') submitForm();
    }

    // при изменении почты
    const onEmailChange = (e) => {
        setLoginState(prevState => { // отображение ошибки
            return {...prevState,
                email: e.target.value,
                emailEmpty: false,
                messageEmpty: false,
                emailInvalid: false,
                errorSubmit: false,
                success: false,
            }
        });
    }

    // при изменении сообщения
    const onMessageChange = (e) => {
        setLoginState(prevState => { // отображение ошибки
            return {...prevState,
                message: e.target.value,
                emailEmpty: false,
                messageEmpty: false,
                emailInvalid: false,
                errorSubmit: false,
                success: false,
            }
        });
    }

    return (
        <Loading isLoading={loginState.loading} alreadyLoaded={true} size='medium' extraClassName='fullwidth'>
            <div className={`form-send-message-wrap ${inlineForm ? 'form-send-message-inline': ''}`}>
                <Label id='email' title='E-mail' input={
                    <Input
                        handleChange={(e) => onEmailChange(e)}
                        type='email'
                        id='email'
                        extraClassName={(loginState.emailEmpty || loginState.emailInvalid) ? 'input-error' : ''}
                        value={loginState.email}
                    />
                } />
                <Label id='message' title={inlineForm ? 'Повідомлення': 'Питання'} input={
                    <textarea
                        onChange={(e) => onMessageChange(e)}
                        className={`textarea ${loginState.messageEmpty ? 'input-error' : ''}`}
                        value={loginState.message}
                        id="message"
                    />
                } />
                <div className="form-submit">
                    {!loginState.emailEmpty ? null :
                        <ErrorLine text='Поле "E-mail" не може бути порожнім' />
                    }
                    {!loginState.messageEmpty ? null :
                        <ErrorLine text='Поле "Питання" не може бути порожнім' />
                    }
                    {!loginState.emailInvalid ? null :
                        <ErrorLine text='Перевірте правильність поля "E-mail"' />
                    }
                    {!loginState.errorSubmit ? null :
                        <ErrorLine text="Помилка надсилання пошти. Спробуй пізніше" />
                    }
                    {(inlineForm && loginState.success) ? <SuccessLine text='Ваше повідомлення успішно доставлено'/> : null}
                    <span>&nbsp;</span>
                    <Button
                        handleClick={inlineForm ? submitInlineForm : submitForm}
                        text='Написати'
                        extraClassName='btn-violet'
                    />
                </div>
            </div>
        </Loading>
    )
}