import {createSlice} from '@reduxjs/toolkit';

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        isLogin: false,
    },
    reducers: {
        logIn(state) {
            state.isLogin = true;
        },
        logOut(state) {
            state.isLogin = false;
            localStorage.clear(); // при логауте в любом случае очищается локальное хранилище
        },
    },
});

export const {logIn, logOut} = AuthSlice.actions;

export default AuthSlice.reducer;