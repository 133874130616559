import React from 'react';
import {Routes, Route, NavLink} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {openModal} from "../../store/modalSlice";

import './header.scss';

import {ImUser} from 'react-icons/im';

export const Header = ({type = 'landing', sidebarToggle, sidebarOff}) => {

    const dispatch = useDispatch();

    // открытие модальных окон для  авторизации
    const burgerClick = (modalName) => {
        dispatch(openModal(modalName));
    }

    return(
        <header>
            <div className='h-logo'>
                <NavLink to={type === 'landing' ? '/' : 'profile'}>
                    <span className='h-logo-title'>Tel.net</span>
                    <span className='h-logo-sub'>.ua</span>
                </NavLink>
            </div>
            {type === 'landing' ?
                <div className='h-auth' onClick={() => burgerClick('auth')}>
                    <p className='landing-h-user-web'>Вхід</p>
                    <p className='landing-h-user-mob'><ImUser/></p>
                </div> :
                <div className='h-auth'>
                    <p className='h-user-web'>
                        <NavLink onClick={sidebarOff} to='profile'>Особистий кабінет</NavLink>
                    </p>
                    <p className='h-user-mob'>
                        <NavLink onClick={sidebarOff} to='profile'><ImUser/></NavLink>
                    </p>
                    <div className="burger-wrap" onClick={sidebarToggle}>
                        <div className="burger">
                            <span/><span/><span/>
                        </div>
                    </div>
                </div>
            }
        </header>
    )

}