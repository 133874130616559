import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {openModal, switchLoading} from "../../store/modalSlice";
import {logIn} from "../../store/authSlice";
import {Input} from "../ui/input/input";
import {Label} from "../ui/label/label";
import {Button} from "../ui/button/button";
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleButtonAuth} from "../ui/google-button-auth/google-button-auth";
import {ErrorLine} from "../text/error-line/error-line";

export const RegisterModal = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // ключ каптчи в зависимости от дев/прод
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

    // состояние текущей формы
    let [registerState, setRegisterState] = useState({
        login: '',
        password: '',
        loginEmpty: false,
        loginInvalid: false,
        passEmpty: false,
        captchaDone: false,
        errorExist: false,
        errorConnect: false,
    })

    // смена модального окна на авторизацию
    const AuthClick = (modalName) => {
        dispatch(openModal(modalName));
    }

    // попытка зарегистрировать пользователя
    const TryRegister = () => {
        setRegisterState(prevState => { // очистка текста ошибок
            return {...prevState, errorExist: false, errorConnect: false, loginEmpty: false, loginInvalid: false, passEmpty: false}
        })

        // включение прелоадера
        dispatch(switchLoading(true));

        // данные логин/пароль в json
        const userData = JSON.stringify({
            login: registerState.login,
            password: registerState.password,
        });

        // поле логина не должно быть пустым
        if(registerState.login.length === 0) {
            registerState(prevState => {
                return {...prevState, loginEmpty: true}
            });
            // выключение прелоадера и завершение функции
            dispatch(switchLoading(false));
            return;
        }

        // поле пароля не должно быть пустым
        if(registerState.password.length === 0) {
            registerState(prevState => {
                return {...prevState, passEmpty: true}
            });
            // выключение прелоадера и завершение функции
            dispatch(switchLoading(false));
            return;
        }

        // запрос на регистрацию
        fetch('https://tel.net.ua/telnet/backend/register.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: userData
        })
            .then(response => response.json())
            .then(response => {
                // выключение прелоадера
                dispatch(switchLoading(false));
                if (response.error) {
                    // если такой пользователь уже существует
                    if(response.error === 'exists') {
                        setRegisterState(prevState => {
                            return {...prevState, errorExist: true}
                        })
                    } else { // любая другая ошибка
                        setRegisterState(prevState => {
                            return {...prevState, errorConnect: true}
                        })
                    }
                } else {
                    // сохранение данных о пользователе в браузере
                    localStorage.setItem('loginData', userData);
                    dispatch(openModal('false')); // закрытие модального окна
                    dispatch(logIn()); // redux что пользователь залогинен
                    navigate('/profile', {replace: true}); // редирект на главную панели управления
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                dispatch(switchLoading(false));
                setRegisterState(prevState => {
                    return {...prevState, errorConnect: true}
                })
            })
    }

    // при успешной валидации гугл каптчи
    const onChange = (value) => {
        setRegisterState(prevState => {
            return {...prevState, captchaDone: true}
        })
        // console.log("Captcha value:", value);
    }

    // если гугл апи не отвечает или нет доступа
    const handleFailure = (params) => {
        if(params.error === 'popup_closed_by_user') return;
        setRegisterState(prevState => { // очистка текста ошибок
            return {...prevState, errorConnect: true}
        })
    }

    // при изменении логина удаляется из инпута всё, проме лат букв, цифр и _
    const onNameChange = (e) => {
        const reg = /[^a-z_\d]/i;
        let login = e.target.value;
        let unusedLetters = login.search(/[^a-z_\d]/i); // поиск запрещённых символов
        login = login.replace(reg, ''); // удаление всех запрещённых символов
        // если найден запрещённый символ
        if(unusedLetters !== -1) {
            setRegisterState(prevState => { // отображение ошибки
                return {...prevState, login: login, loginInvalid: true}
            });
        } else {
            setRegisterState(prevState => {
                return {...prevState, login: login, loginInvalid: false}
            });
        }
    }

    // сохранение пароля при изменении
    const onPassChange = (e) => {
        setRegisterState(prevState => {
            return {...prevState, password: e.target.value, loginInvalid: false}
        })
    }

    // нажатие на Ентер при фокусе на текстовом поле
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') TryRegister();
    }

    return (
        <div className='auth-wrap'>
            <Label id='login' title='Логін' input={
                <Input
                    handleChange={(e) => onNameChange(e)}
                    handleKeyDown={(e) => handleKeyDown(e)}
                    type='text'
                    id='login'
                    value={registerState.login}
                />
            } />
            <Label id='password' title='Пароль' input={
                <Input
                    handleChange={(e) => onPassChange(e)}
                    handleKeyDown={(e) => handleKeyDown(e)}
                    type='password'
                    id='password'
                    value={registerState.password}
                />
            } />
            <div className="auth-login">
                <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={onChange}
                />
                {registerState.captchaDone ?
                    <Button
                        handleClick={() => {TryRegister()}}
                        text='Зареєструватися'
                        extraClassName='btn-violet'
                    /> :
                    <Button text='Зареєструватися' disable={true}/>
                }
                {!registerState.errorExist ? '' :
                    <ErrorLine text="Такий акаунт вже існує" />
                }
                {!registerState.errorConnect ? '' :
                    <ErrorLine text="Помилка під час реєстрації" />
                }
                {!registerState.loginEmpty ? '' :
                    <ErrorLine text='Поле "Логін" не може бути порожнім' />
                }
                {!registerState.passEmpty ? '' :
                    <ErrorLine text='Поле "Пароль" не може бути порожнім' />
                }
                {!registerState.loginInvalid ? '' :
                    <ErrorLine text="Можна використовувати лише латинські символи, цифри та підкреслення" />
                }
            </div>
            <div className="auth-bottom">
                <GoogleButtonAuth
                    text='Увійти через Google'
                    extraClassName='btn-blue'
                    handleFailure={handleFailure}
                />
                <Button
                    handleClick={() => AuthClick('auth')}
                    text='Увійти'
                    extraClassName='btn-violet'
                />
            </div>
        </div>
    )
}