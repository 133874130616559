import React from 'react';
import {ModalSendMessage} from "../../components/modal-send-message/modal-send-message";

export const Support = () => {

    return (
        <div>
            <h1>Допомога та підтримка</h1>
            <div className='content-wrap email-wrap'>
                <p className='mb15'>Якщо ви зіткнулися з якоюсь проблемою або потребуєте якогось функціоналу, або просто хочете написати програмісту - заповніть форму</p>
                <ModalSendMessage inlineForm={true}/>
            </div>
        </div>
    )

}