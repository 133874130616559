import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Button} from "../../components/ui/button/button";
import {Loading} from "../../components/loading/loading";

export const ProfileStatus = ({data}) => {

    let [activationState, setActivationState] = useState({
        status: data.status,
        error: false,
        loading: false
    });

    // переключение статуса аккаунта
    const switchAccount = () => {

        let status = activationState.status === 'active' ? 'disable': 'active';

        // включение прелоадера
        setActivationState(prevState => {
            return {
                ...prevState,
                loading: true,
                error: false,
            }
        });

        // переключение статуса аккаунта
        fetch('https://tel.net.ua/telnet/backend/profile/switch-status.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                status: status,
            })
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    // выключение прелоадера и отображение ошибки
                    setActivationState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            error: true,
                        }
                    });
                } else {
                    // изменение статуса
                    setActivationState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            status: status,
                        }
                    });
                }
            })
            .catch(() => {
                // выключение прелоадера и отображение ошибки
                setActivationState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                });
            })
    }

    return (
        <div className="content-wrap activation-wrap">
            <Loading isLoading={activationState.loading} alreadyLoaded={true}>
                <div>
                    <p>Незалежно від підписки ви можете вмикати або вимикати відображення сторінки за посиланням. Зараз ваш аккаунт {activationState.status === 'active' ? <span className='green'><b>увімкнений</b></span> : <span className='red'><b>вимкнений</b></span>}</p>
                    <Button
                        text={activationState.status === 'active' ? 'Вимкнути' : 'Увімкнути'}
                        handleClick={() => switchAccount()}
                        extraClassName={activationState.status === 'active' ? 'btn-red' : 'btn-blue'}
                    />
                    {activationState.error ? <p className='red'>Помилка зміни статусу. Зверніться до <NavLink to='support'><u>техпідтримки</u></NavLink></p>: null}
                </div>
            </Loading>
        </div>
    )

}