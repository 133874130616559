import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {BiText} from 'react-icons/bi';
import {FaArrowDown} from 'react-icons/fa';
import {FaArrowUp} from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';
import {changeTrigger, setLoader} from "../../store/siteData";
import {ErrorLine} from "../../components/text/error-line/error-line";

export const EditorParts = ({data, partData, id}) => {

    // console.log(partData)
    // console.log(data)

    const dispatch = useDispatch();
    let trigger = useSelector(state => state.module.trigger);
    id = parseInt(id);

    let [moduleState, setModuleState] = useState({
        error: '',
    });

    let userData = JSON.parse(localStorage.getItem('userData')); // данные логина
    let size = Object.keys(data).length; // количество блоков страницы пользователя

    // изменение последовательности данных. idUpdate - модификатор изменения
    const updatePart = (command, value) => {

        // сброс текста ошибки
        setModuleState(prevState => {
            return {
                ...prevState,
                error: '',
            }
        })

        dispatch(setLoader(true)); // включение прелоадера

        // если данные нужно сдвинуть на одну позицию
        if(command === 'slice') {
            // перетасовка массива данных страницы с учётом сдвига
            let newId = id + value;
            let tempPart = data[newId]; // временно сохранение данных вместо которых буду установлены текущие (новые)
            data[newId] = data[id]; // установка текущих данных на новое место
            data[id] = tempPart; // а данные из нового места устанавливаются на текущее
        } else { // иначе текущий блок подлежит удалению
            Object.entries(data).map(([index]) => {

                let indexNumber = parseInt(index)
                let idNumber = parseInt(id);

                if(indexNumber >= idNumber) {
                    if(indexNumber === Object.keys(data).length) {
                        delete (data[indexNumber])
                    } else data[indexNumber] = data[indexNumber + 1];
                }

            })
        }

        fetch('https://tel.net.ua/telnet/backend/editor/update-data.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: userData.login,
                password: userData.password,
                data: data,
            })
        })
            .then(response => response.json())
            .then(response => {
                dispatch(setLoader(false)); // выключение прелоадера
                if (response.error) {
                    setModuleState(prevState => {
                        return {
                            ...prevState,
                            error: 'Ошибка при обновлении',
                        }
                    })
                } else {
                    dispatch(changeTrigger(!trigger)); // повторное получение данных
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                dispatch(setLoader(false)); // выключение прелоадера
                setModuleState(prevState => {
                    return {
                        ...prevState,
                        error: 'Ошибка при обновлении',
                    }
                })
            })
    }

    return (
        <div className="editor-single-part">
            <div className='esp-icon'>
                {(() => {
                    switch (partData.name) {
                        case 'text':
                            return (
                                <BiText/>
                            )
                        default:
                            return null
                    }
                })()}
            </div>
            <div className="esp-body">
                {(() => {
                    switch (partData.name) {
                        case 'text':
                            return (
                                <div>
                                    <pre className={`text-${partData.align} 
                                                size-${partData.size}
                                                ${partData.weight !== '0' ? 'weight' : ''}
                                                ${partData.italic !== '0' ? 'italic' : ''}
                                                ${partData.upper !== '0' ? 'upper' : ''}`}>
                                        {partData.text}
                                    </pre>
                                </div>
                            )
                        default:
                            return null
                    }
                })()}
            </div>
            <div className="esp-control">
                <div className="esp-order">
                    {id !== 1 ?
                        <div title='Переместить наверх'
                             className={`esp-order-up ${id === size ? 'esp-order-up-bordered' : '' }`}
                             onClick={() => updatePart('slice', -1)}>
                            <FaArrowUp/>
                        </div> : null
                    }
                    {id !== size ?
                        <div title='Переместить вниз'
                             className="esp-order-down"
                             onClick={() => updatePart('slice', 1)}>
                            <FaArrowDown/>
                        </div> : null
                    }
                </div>
                <div title='Удалить'
                     className="esp-delete"
                     onClick={() => updatePart('delete', id)}>
                    <MdDelete/>
                </div>
            </div>
            {moduleState.error.length > 0 ? <ErrorLine text={moduleState.error}/> : null}
        </div>
    )
}