import React from 'react';
import {useSelector} from 'react-redux';
import {Loading} from "../../components/loading/loading";
import {PreviewParts} from "./preview-parts/preview-parts";

import {BiWifi} from 'react-icons/bi';
import {FaBatteryFull} from 'react-icons/fa';

export const EditorPreview = ({data}) => {

    // текущее значение временного модуля для добавления на страницу
    // по умолчанию там пустота
    const module = useSelector(state => state.module.module);
    const loading = useSelector(state => state.module.loading);

    return (
        <div className='e-preview'>
            <div className='ep-inner-wrap'>
                <div className="phone-bar"><BiWifi/><FaBatteryFull/></div>
                <Loading alreadyLoaded={true} isLoading={loading} extraClassName='ep-loading-wrap' size='medium'>
                    <div className="ep-inner">
                        {
                            Object.keys(data).map(( key) => {
                                return(
                                    <PreviewParts key={key} partData={data[key]}/>
                                )
                            })
                        }
                        <PreviewParts partData={module}/>
                    </div>
                </Loading>
            </div>
        </div>
    )
}