import React from 'react';
import {useSelector} from 'react-redux';
import {Loading} from "../../components/loading/loading";
import {EditorParts} from "./editor-parts";

export const EditorExistsData = ({data}) => {

    // // текущее значение временного модуля для добавления на страницу
    // // по умолчанию там пустота
    // const module = useSelector(state => state.module.module);
    const loading = useSelector(state => state.module.loading);

    return (
        <div>
            <Loading alreadyLoaded={true} isLoading={loading} size='medium'>
                <div className="editor-parts-wrap">
                    {
                        Object.keys(data).map(( key) => {
                            return(
                                <EditorParts key={key} data={data} partData={data[key]} id={key}/>
                            )
                        })
                    }
                </div>
            </Loading>
        </div>
    )
}