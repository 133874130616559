import React from 'react';
import {useSelector} from 'react-redux';
import {ModalTemplate} from "./modal-template";
import {AuthModal} from "../auth-modal/auth-modal";
import {RegisterModal} from "../register-modal/register-modal";
import {ModalSendMessage} from "../modal-send-message/modal-send-message";

import './modal.scss';
import {ModalSuccess} from "../modal-success/modal-success";

export const Modal = () => {

    // в зависимости от того, какое окно указано в redux - рендер
    const modalName = useSelector(state => state.modals.openedModal);

    return(
        <div>
            {(() => {
                switch (modalName) {
                    case 'auth':
                        return <ModalTemplate
                            title="Авторизація"
                            body={<AuthModal/>}
                        />
                    case 'reg':
                        return <ModalTemplate
                            title="Реєстрація"
                            body={<RegisterModal/>}
                        />
                    case 'form':
                        return <ModalTemplate
                            title="Будь яке питання"
                            body={<ModalSendMessage/>}
                        />
                    case 'send-success':
                        return <ModalTemplate
                            title="Дякуємо"
                            body={<ModalSuccess/>}
                        />
                    default:
                        return null
                }
            })()}
        </div>
    )

}