import React from 'react';
import {useDispatch} from 'react-redux';
import {GoogleLogin} from 'react-google-login';
import {Button} from "../button/button";
import {openModal} from "../../../store/modalSlice";
import {logIn} from "../../../store/authSlice";

export const GoogleButtonAuth = ({text, extraClassName, handleFailure}) => {

    const dispatch = useDispatch();

    // попытка зарегистрировать пользователя с помощью гугла
    const GoogleRegister = (response) => {

        // полученные данные по апи от гугла
        const userData = JSON.stringify({
            login: response.googleId,
            password: response.googleId, // подробнее в google-register backend
            googleId: response.googleId, // уникальный номер
            name: response.profileObj.name, // привязанное имя к аккаунту
            email: response.profileObj.email, // привязанная почта к аккаунту
        });

        fetch('https://tel.net.ua/telnet/backend/register-google.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: userData
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    handleFailure();
                } else {
                    // сохранение данных о пользователе в браузере
                    localStorage.setItem('loginData', userData);
                    dispatch(openModal('false')); // закрытие модального окна
                    dispatch(logIn()); // redux что пользователь залогинен
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                handleFailure();
            })
    }

    return (
        <GoogleLogin
            clientId="893354226606-c123ful2o78hjonbje0qnm08c4o2hl11.apps.googleusercontent.com"
            render={renderProps => (
                <Button
                    handleClick={renderProps.onClick}
                    text={text}
                    extraClassName={extraClassName}
                    disabled={renderProps.disabled}
                />
            )}
            onSuccess={GoogleRegister}
            onFailure={handleFailure}
            prompt={'select_account'}
            cookiePolicy={'single_host_origin'}
        />
    )
}