import {createSlice} from '@reduxjs/toolkit';

export const ModalSlice = createSlice({
    name: 'modals',
    initialState: {
        openedModal: false,
        loading: false,
    },
    reducers: {
        openModal(state, action) {
            state.openedModal = action.payload;
            state.loading = false; // при открытии модального окна любые прелоадеры выключаются
        },
        switchLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const {openModal, switchLoading} = ModalSlice.actions;

export default ModalSlice.reducer;