import React from 'react';
import './label.scss';

export const Label = ({id, title, input, extraClassName = ''}) => {
    return (
        <label htmlFor={`${id}`} className={`label ${extraClassName}`}>
            <span>{title}</span>
            {input}
        </label>
    )
}