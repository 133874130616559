import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Loading} from "../loading/loading";
import {ErrorLine} from "../text/error-line/error-line";
import {Button} from "../ui/button/button";

export const LiqPay = ({price, months}) => {

    let data = JSON.parse(localStorage.getItem('userData'));

    let [liqpayState, setLiqpayState] = useState({
        data: false,
        sign: false,
        order: false,
        loading: true,
        loadingRegister: false,
        errorRegister: false,
        error: false,
    });

    // получение данных для кнопки оплаты
    const GetLiqpayDataSign = () => {
        fetch('https://tel.net.ua/telnet/backend/payment/get-data-sign.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                price: price,
                months: months,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setLiqpayState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            error: true,
                        }
                    })
                } else {
                    setLiqpayState(prevState => {
                        return {
                            ...prevState,
                            data: response.data,
                            sign: response.sign,
                            order: response.order,
                            loading: false,
                        }
                    })
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                setLiqpayState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                })
            })
    }

    // создание заявки на оплату
    const registerPayment = (e) => {
        e.preventDefault(); // приостановка отправки формы
        // включение прелоадера
        setLiqpayState(prevState => {
            return {
                ...prevState,
                loadingRegister: true,
                errorRegister: false,
            }
        })

        fetch('https://tel.net.ua/telnet/backend/payment/register-payment.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                price: price,
                months: months,
                data: liqpayState.data,
                order: liqpayState.order,
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setLiqpayState(prevState => {
                        return {
                            ...prevState,
                            loadingRegister: false,
                            errorRegister: true,
                        }
                    })
                } else {
                    setLiqpayState(prevState => {
                        return {
                            ...prevState,
                            loadingRegister: false,
                        }
                    })
                    document.getElementById(`liqpay-${price}-${months}`).submit(); // отправка формы
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                setLiqpayState(prevState => {
                    return {
                        ...prevState,
                        loadingRegister: false,
                        errorRegister: true,
                    }
                })
            })
    }

    // очистка поля с ошибкой, чтобы можно было попробовать оплатить ещё раз
    const tryAgain = () => {
        setLiqpayState(prevState => {
            return {
                ...prevState,
                errorRegister: false,
            }
        })
    }

    // при прогрузке страницы
    useEffect(() => {
        GetLiqpayDataSign();
    }, [])

    return (
        <>
            {liqpayState.loading ?
                <Loading isLoading={true}/> :
                <div>
                    {liqpayState.error ?
                        <ErrorLine text='Наразі оплата не можлива'/> :
                        <>
                            {liqpayState.errorRegister ?
                                <>
                                    <p className='red'>Помилка при оплаті. Якщо ця помилка не пропадає - зверніться до <NavLink to='support'><u>техпідтримки</u></NavLink></p>
                                    <Button
                                        handleClick={tryAgain}
                                        text='Спробувати ще раз'
                                        extraClassName='btn-orange'
                                    />
                                </> :
                                <form method="POST" id={`liqpay-${price}-${months}`}
                                      action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                                    <input type="hidden" name="data" value={liqpayState.data}/>
                                    <input type="hidden" name="order" value={liqpayState.order}/>
                                    <input type="hidden" name="signature" value={liqpayState.sign}/>
                                    <Button
                                        handleClick={registerPayment}
                                        text='Сплатити'
                                        extraClassName='btn-violet'
                                    />
                                </form>
                            }
                        </>
                    }
                </div>
            }
        </>
    )

}