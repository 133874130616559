import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {changeTempModule, setLoader, changeTrigger} from "../../store/siteData";

import {Loading} from "../../components/loading/loading";
import {ErrorLine} from "../../components/text/error-line/error-line";
import {Button} from "../../components/ui/button/button";

import {BiAlignLeft} from 'react-icons/bi';
import {AiOutlineAlignCenter} from 'react-icons/ai';
import {BiAlignRight} from 'react-icons/bi';
import {BiBold} from 'react-icons/bi';
import {BiItalic} from 'react-icons/bi';
import {TbLetterCaseUpper} from 'react-icons/tb';

export const ModuleText = () => {

    const dispatch = useDispatch();
    let loading = useSelector(state => state.module.loading);
    let trigger = useSelector(state => state.module.trigger);

    let data = JSON.parse(localStorage.getItem('userData'));

    let [moduleState, setModuleState] = useState({
        name: 'text',
        error: false,
        text: '',
        align: 'center',
        size: '3',
        weight: "0",
        italic: "0",
        upper: "0",
    });

    // изменение текста
    const setText = (event) => {
        setModuleState(prevState => {
            return {
                ...prevState,
                text: event.target.value,
            }
        })
    }

    // установка размера шрифта
    const setSize = (event) => {
        setModuleState(prevState => {
            return {
                ...prevState,
                size: event.target.value,
            }
        })
    }

    // установка оцентровки
    const setAlign = (align) => {
        setModuleState(prevState => {
            return {
                ...prevState,
                align: align,
            }
        })
    }

    // переключение жирности
    const toggleWeight = () => {
        let newWeight = moduleState.weight === '0' ? '1' : '0';
        setModuleState(prevState => {
            return {
                ...prevState,
                weight: newWeight,
            }
        })
    }

    // переключение курсива
    const toggleItalic = () => {
        let newItalic = moduleState.italic === '0' ? '1' : '0';
        setModuleState(prevState => {
            return {
                ...prevState,
                italic: newItalic,
            }
        })
    }

    // переключение регистра
    const toggleUpper = () => {
        let newUpper = moduleState.upper === '0' ? '1' : '0';
        setModuleState(prevState => {
            return {
                ...prevState,
                upper: newUpper,
            }
        })
    }

    // сохранение текущего модуля
    const handleSave = () => {
        dispatch(setLoader(true)); // включение прелоадера
        setModuleState(prevState => {
            return {
                ...prevState,
                error: false,
            }
        })

        fetch('https://tel.net.ua/telnet/backend/editor/save-part.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                name: moduleState.name,
                text: moduleState.text,
                align: moduleState.align,
                size: moduleState.size,
                weight: moduleState.weight,
                italic: moduleState.italic,
                upper: moduleState.upper,
            })
        })
            .then(response => response.json())
            .then(response => {
                dispatch(setLoader(false)); // выключение прелоадера
                if (response.error) {
                    setModuleState(prevState => {
                        return {
                            ...prevState,
                            error: true,
                        }
                    })
                } else {
                    dispatch(changeTrigger(!trigger)); // повторное получение данных
                }
            })
            // если произошла ошибка при запросе
            .catch(() => {
                dispatch(setLoader(false)); // выключение прелоадера
                setModuleState(prevState => {
                    return {
                        ...prevState,
                        error: true,
                    }
                })
            })
    }

    // при изменении любого параметра - передача его в превью
    useEffect(() => {
        dispatch(changeTempModule(moduleState))
    }, [moduleState])

    return (
        <Loading alreadyLoaded={true} isLoading={loading} width='100%' size='medium'>
            <div className='module-wrap module-text'>
                <div className='module-panel'>
                    <label className='label'>
                        <textarea
                            value={moduleState.text}
                            onChange={(e) => setText(e)}
                            className={`textarea 
                                text-${moduleState.align} 
                                ${moduleState.weight !== '0' ? 'weight' : ''} 
                                ${moduleState.italic !== '0' ? 'italic' : ''} 
                                ${moduleState.upper !== '0' ? 'upper' : ''}
                                size-${moduleState.size}`}
                        />
                    </label>
                    <Button
                        handleClick={handleSave}
                        extraClassName='btn-blue'
                        text='Сохранить'
                        disable={moduleState.text.length === 0} // true, если введён хоть один символ
                    />
                    {moduleState.error ? <ErrorLine text='Ошибка при сохранении'/> : null}
                </div>
                <div className='module-panel'>
                    <select onChange={(e) => setSize(e)} value={moduleState.size}>
                        <option value="1">Крошечный</option>
                        <option value="2">Маленький</option>
                        <option value="3">Средний</option>
                        <option value="4">Большой</option>
                        <option value="5">Огромный</option>
                    </select>
                    <div className="m-group">
                        <p><Button handleClick={() => setAlign('left')} extraClassName={`icon ${moduleState.align === 'left' ? 'active' : ''}`} icon={<BiAlignLeft/>}/></p>
                        <p><Button handleClick={() => setAlign('center')} extraClassName={`icon ${moduleState.align === 'center' ? 'active' : ''}`} icon={<AiOutlineAlignCenter/>}/></p>
                        <p><Button handleClick={() => setAlign('right')} extraClassName={`icon ${moduleState.align === 'right' ? 'active' : ''}`} icon={<BiAlignRight/>}/></p>
                    </div>
                    <div className="m-group">
                        <p><Button handleClick={toggleWeight} extraClassName={`icon ${moduleState.weight !== '0' ? 'active' : ''}`} icon={<BiBold/>}/></p>
                        <p><Button handleClick={toggleItalic} extraClassName={`icon ${moduleState.italic !== '0' ? 'active' : ''}`} icon={<BiItalic/>}/></p>
                        <p><Button handleClick={toggleUpper} extraClassName={`icon ${moduleState.upper !== '0' ? 'active' : ''}`} icon={<TbLetterCaseUpper/>}/></p>
                    </div>
                </div>
            </div>
        </Loading>
    )

}