import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Button} from "../../components/ui/button/button";
import {Loading} from "../../components/loading/loading";
import {Input} from "../../components/ui/input/input";
import {ErrorLine} from "../../components/text/error-line/error-line";
import {SuccessLine} from "../../components/text/success-line/success-line";

export const ProfilePassword = ({data}) => {

    let [passwordState, setPasswordState] = useState({
        password: '',
        newPassword: '',
        newPasswordRepeat: '',
        error: false,
        errorCurrentPass: false,
        errorRepeat: false,
        loading: false,
        success: false,
    });

    // сохранение нового пароля
    const changePassword = () => {

        let loginData = JSON.parse(localStorage.getItem('loginData')); // логин/пароль

        // включение прелоадера
        setPasswordState(prevState => {
            return {
                ...prevState,
                loading: true,
                error: false,
                errorCurrentPass: false,
                errorRepeat: false,
                success: false,
            }
        });

        // проверка старого пароля
        if(loginData.password !== passwordState.password) {
            setPasswordState(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    errorCurrentPass: true,
                }
            });
            return;
        }

        // проверка совпадений инпутов нового пароля
        if(passwordState.newPassword !== passwordState.newPasswordRepeat) {
            setPasswordState(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    errorRepeat: true,
                }
            });
            return;
        }

        // сохранение нового пароля
        fetch('https://tel.net.ua/telnet/backend/profile/change-password.php', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                login: data.login,
                password: data.password,
                newPassword: passwordState.newPassword,
            })
        }).then(response => response.json())
            .then(response => {
                if (response.error) {
                    // выключение прелоадера и отображение ошибки
                    setPasswordState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            error: true,
                        }
                    });
                } else {
                    // изменение статуса
                    setPasswordState(prevState => {
                        return {
                            ...prevState,
                            loading: false,
                            password: '',
                            newPassword: '',
                            newPasswordRepeat: '',
                            success: true,
                        }
                    });
                }
            })
            .catch(() => {
                // выключение прелоадера и отображение ошибки
                setPasswordState(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        error: true,
                    }
                });
            })
    }

    // изменение инпута старого пароля
    const onPasswordChange = (e) => {
        setPasswordState(prevState => { // отображение ошибки
            return {
                ...prevState,
                password: e.target.value,
                error: false,
                errorCurrentPass: false,
                errorRepeat: false,
                success: false,
            }
        });
    }

    // изменение инпута нового пароля
    const onNewPasswordChange = (e) => {
        setPasswordState(prevState => { // отображение ошибки
            return {
                ...prevState,
                newPassword: e.target.value,
                error: false,
                errorCurrentPass: false,
                errorRepeat: false,
                success: false,
            }
        });
    }

    // изменение инпута повтора нового пароля
    const onNewPasswordRepeatChange = (e) => {
        setPasswordState(prevState => { // отображение ошибки
            return {
                ...prevState,
                newPasswordRepeat: e.target.value,
                error: false,
                errorCurrentPass: false,
                errorRepeat: false,
                success: false,
            }
        });
    }

    // нажатие на Ентер при фокусе на текстовом поле
    const onPasswordKeydown = (e) => {
        if (e.key === 'Enter' && passwordState.password.length > 0 && passwordState.newPassword.length > 0 && passwordState.newPasswordRepeat.length > 0) changePassword();
    }

    return (
        <>
            { // для гугл пользователей пароль менять нельзя
                data.googleName.length > 0 ? null :
                    <div className="content-wrap activation-wrap">
                        <Loading isLoading={passwordState.loading} alreadyLoaded={true}>
                            <div>
                                <p>Змінити пароль:</p>
                                <p className='text-sub'>*Щоб змінити пароль, потрібно ввести правильно старий пароль і двічі новий</p>
                                <div className='pass-line'>
                                    <p>Старий пароль</p>
                                    <Input
                                        type='password'
                                        value={passwordState.password}
                                        handleChange={(e) => onPasswordChange(e)}
                                        handleKeyDown={(e) => onPasswordKeydown(e)}
                                    />
                                </div>
                                <div className='pass-line'>
                                    <p>Новий пароль</p>
                                    <Input
                                        type='password'
                                        value={passwordState.newPassword}
                                        handleChange={(e) => onNewPasswordChange(e)}
                                        handleKeyDown={(e) => onPasswordKeydown(e)}
                                    />
                                </div>
                                <div className='pass-line'>
                                    <p>Підтвердити новий пароль</p>
                                    <Input
                                        type='password'
                                        value={passwordState.newPasswordRepeat}
                                        handleChange={(e) => onNewPasswordRepeatChange(e)}
                                        handleKeyDown={(e) => onPasswordKeydown(e)}
                                    />
                                </div>
                                {passwordState.errorCurrentPass ? <ErrorLine text='Введіть правильно старий пароль'/>: null}
                                {passwordState.errorRepeat ? <ErrorLine text='Паролі не співпадають'/>: null}
                                {
                                    (passwordState.password.length > 0 && passwordState.newPassword.length > 0 && passwordState.newPasswordRepeat.length > 0) ?
                                        <Button
                                            text='Змінити'
                                            handleClick={() => changePassword()}
                                            extraClassName='btn-blue'
                                        /> :
                                        <Button
                                            text='Змінити'
                                            handleClick={() => changePassword()}
                                            extraClassName='btn-gray'
                                            disable='disable'
                                        />
                                }
                                {passwordState.error ? <p className='red'>Помилка зміни паролю. Зверніться до <NavLink to='support'><u>техпідтримки</u></NavLink></p> : null}
                                {passwordState.success ? <SuccessLine text='Пароль успішно змінено'/> : null}
                            </div>
                        </Loading>
                    </div>
            }
        </>

    )

}