import React, {useState} from 'react';
import {Routes, Route, NavLink, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logOut} from "../../store/authSlice";
import {Header} from "../../components/header/header";
import {Support} from "../support/support";
import {Profile} from "../profile/profile";
import {Editor} from "../editor/editor";
import {Payment} from "../payment/payment";
import {Button} from "../../components/ui/button/button";

import './dashboard.scss';

import {BiLogOut} from 'react-icons/bi';

export const Dashboard = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // состояние текущей формы
    let [dashboardState, setDashboardState] = useState({
        currentPage: 'profile',
        mobileMenu: false,
    });

    // выход из аккаунта
    const LogOut = () => {
        dispatch(logOut());
        navigate('/', {replace: true}); // редирект на главную
    }

    // переключение активного блока (псевдостраницы)
    const setPage = (page) => {
        setDashboardState(prevState => {
            return {...prevState, currentPage: page, mobileMenu: false}
        })
    }

    // переключение сайдбара
    const sidebarToggle = () => {
        setDashboardState(prevState => {
            return {...prevState, mobileMenu: !dashboardState.mobileMenu}
        })
    }

    // выключение сайдбара
    const sidebarOff = () => {
        setDashboardState(prevState => {
            return {...prevState, mobileMenu: false}
        })
    }

    return (
        <>
            <Header type='dashboard' profileClick={() => setPage('profile')} sidebarToggle={sidebarToggle} sidebarOff={sidebarOff}/>
            <div className="dash-body">
                <div className={`sidebar ${dashboardState.mobileMenu ? 'active' : ''}`}>
                    <div className="inner">
                        <ul>
                            <li><NavLink onClick={sidebarOff} to='editor'>Ваша сторінка</NavLink></li>
                            <li><NavLink onClick={sidebarOff} to='profile'>Профіль</NavLink></li>
                            <li><NavLink onClick={sidebarOff} to='payment'>Підписка</NavLink></li>
                            <li><NavLink onClick={sidebarOff} to='support'>Допомога та підтримка</NavLink></li>
                        </ul>
                        <div className='logout'>
                            <Button
                                extraClassName='btn-red'
                                handleClick={LogOut}
                                icon={<BiLogOut/>}
                                text='Вихід'
                            />
                        </div>
                    </div>
                    <div className="sidebar-overflow" onClick={sidebarOff}/>
                </div>
                <div className="content">
                    <Routes basename='/'>
                        <Route path='editor' element={<Editor/>}/>
                        <Route path='payment' element={<Payment/>}/>
                        <Route path='support' element={<Support/>}/>
                        <Route path='*' element={<Profile/>}/>
                    </Routes>
                </div>
            </div>

        </>
    )

}